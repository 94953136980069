import React, { ReactNode } from 'react';
import { RouteComponentProps } from '@reach/router';

import CollegeFinderContextProvider from './context';

type CollegeFinderProps = RouteComponentProps & {
  children?: ReactNode;
};
const CollegeFinder = (props: CollegeFinderProps) => (
  <CollegeFinderContextProvider>{props.children}</CollegeFinderContextProvider>
);

export default CollegeFinder;
