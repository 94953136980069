import styled from '@emotion/styled';
import { Form, Modal } from 'antd';
import { FilterFilled } from '@ant-design/icons';
import AntdIcon from '@ant-design/icons-react';
import React, { useState, useContext } from 'react';

import { formSchema, ISectionItem } from '../schema';
import { CollegeFinderContext } from '../context';
import { Button, Tooltip } from '../../../components';
import { IFilter, CollegeFinderFilters } from '../../../types/models/college-finder';
import { Select, FormElementChangeEvent, OptionProps } from '../../../components/forms';

const Container = styled.div`
  max-height: 65vh;
  overflow-y: scroll;
`;

const Section = styled.div`
  color: #000;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: bold;
`;

const FilterItemRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButtonDiv = styled.div`
  display: flex;
  align-items: center;
`;

const COST_OF_ATTENDANCE: keyof IFilter = 'familyIncomeRange';
const INCOME_RANGE: keyof IFilter = 'averageTotalCostOfAttendance';
const ETHNICITY: keyof IFilter = 'ethnicities';
const ETHNICITY_ENROLLMENT_RATE: keyof IFilter = 'ethnicitiesEnrollmentRate';

const MULTIPLE_SELECT_PLACEHOLDER = 'Select one or more';

const CollegeFinderModal = ({ clearFields }: { clearFields: () => void }) => {
  const context = useContext(CollegeFinderContext);

  const [modalOpened, setModalOpened] = useState(false);
  const [state, setState] = useState<CollegeFinderFilters>(context.filters);

  /*
    Net income and cost must be used together. Both must be true, has state, or false, has no state to submit.  Users may not submit net income without net cost and vise versa.
  */
  const hasIncomeRange = !!state[INCOME_RANGE];
  const hasAverageCost = !!state[COST_OF_ATTENDANCE];

  const hasCostToAttendError = hasIncomeRange && !!state[COST_OF_ATTENDANCE] === false;
  const hasIncomeError = hasAverageCost && !!state[INCOME_RANGE] === false;

  const hasEthnicityEnrollmentError =
    !!state[ETHNICITY_ENROLLMENT_RATE]?.length && !!state[ETHNICITY]?.length === false;

  const hasError = hasCostToAttendError || hasIncomeError || hasEthnicityEnrollmentError;

  const handleOpenFilter = () => {
    setState(context.filters);
    setModalOpened(true);
  };

  const closeModal = () => {
    setModalOpened(false);
  };

  const clearFilters = () => {
    setState({});
    clearFields();
    context.setFilters({});
    closeModal();
  };

  const saveFilter = () => {
    context.setFilters(state);
    closeModal();
  };

  const getFieldChangeHandler =
    (filterKey: keyof IFilter) =>
    ({ value }: FormElementChangeEvent) => {
      setState((current) => ({
        ...current,
        [filterKey]: value,
      }));
    };

  const renderSelectItem = ({ label, name, options, tooltip }: ISectionItem) => {
    let selectOptions: OptionProps = undefined;
    let hasMultipleMode = true;
    let placeholder = MULTIPLE_SELECT_PLACEHOLDER;

    if (name === 'ethnicities') {
      selectOptions = context.ethnicities?.map(({ displayText: label, id: value }) => ({
        label,
        value,
      }));
    }
    if (name === 'majors') {
      selectOptions = context.majors?.map(({ displayText: label, id: value }) => ({
        label,
        value,
      }));
    }
    if (
      [
        'mathMedian',
        'sat_write_median',
        'actComprehensiveScore',
        'verbalMedian',
        'enrollmentRate',
        'familyIncomeRange',
        'averageTotalCostOfAttendance',
        'percentStudentsReceivingFinancialAid',
        'ethnicitiesEnrollmentRate',
        'averageGraduationRate',
        'medianGraduateIncome',
      ].includes(name)
    ) {
      hasMultipleMode = false;
      placeholder = 'Select one';
    }

    return (
      <Form.Item label={label} key={name}>
        <FilterItemRow>
          <Select
            style={{ maxWidth: 700, flex: 1 }}
            mode={hasMultipleMode ? 'multiple' : undefined}
            placeholder={placeholder}
            value={state?.[name] || undefined}
            name={name}
            filterOption={(inputValue: any, option: any) =>
              option &&
              option.props !== null &&
              option.props.title !== null &&
              option.props.title.toLowerCase().includes(inputValue.toLowerCase())
            }
            options={selectOptions ?? options}
            onChange={getFieldChangeHandler(name)}
          />
          {tooltip && <Tooltip title={tooltip} />}
        </FilterItemRow>
        {name === INCOME_RANGE && hasIncomeError && <p>Please select a household income</p>}
        {name === COST_OF_ATTENDANCE && hasCostToAttendError && <p>Please select total cost to attend</p>}
        {name === ETHNICITY_ENROLLMENT_RATE && hasEthnicityEnrollmentError && <p>Please select Race/Ethnicity</p>}
      </Form.Item>
    );
  };

  return (
    <>
      <Button key="filter" type="link" onClick={handleOpenFilter}>
        <StyledButtonDiv>
          <AntdIcon type={FilterFilled} />
          <div style={{ marginLeft: 5 }}>More filters</div>
        </StyledButtonDiv>
      </Button>
      <Modal
        visible={modalOpened}
        title={'More Filters'}
        closable={true}
        onCancel={closeModal}
        footer={[
          <Button key="clear" type="link" style={{ float: 'left' }} onClick={clearFilters}>
            Clear Filters
          </Button>,

          <Button key="cancel" type="default" onClick={closeModal}>
            Cancel
          </Button>,

          <Button disabled={hasError} key="apply" type="primary-blue" onClick={saveFilter}>
            Apply Filters
          </Button>,
        ]}
      >
        <Container>
          {formSchema.map(({ sectionLabel, items }) => {
            return (
              <div key={sectionLabel}>
                <Section>{sectionLabel}</Section>
                {items.map(renderSelectItem)}
              </div>
            );
          })}
        </Container>
      </Modal>
    </>
  );
};

export default CollegeFinderModal;
