import React from 'react';
import { Router } from '@reach/router';

import CollegeFinderDetail from '../containers/college-finder/college-finder-detail';
import CollegeFinder from '../containers/college-finder';
import CollegeFinderList from '../containers/college-finder/college-finder-list';
import PublicLayout from '../layout/public-layout';

export default function CollegeFinderPage() {
  return (
    <PublicLayout seoTitle="College Finder">
      <Router>
        <CollegeFinder path="/college-finder">
          <CollegeFinderList path="/" />
          <CollegeFinderDetail path=":id" />
        </CollegeFinder>
      </Router>
    </PublicLayout>
  );
}
