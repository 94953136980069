import axios from 'axios';
import { get } from 'lodash';
import produce from 'immer';

import { stringifyCollegeQueryFilter } from './college-finder-utils';

import { API_URL } from '../../constants';
import responseParser from '../response-parser';
import { createError } from '../error';
import {
  University,
  MajorClassification,
  Ethnicity,
  CollegeFinderFilters,
  UniversityResponse,
} from '../../types/models/college-finder';

export const DEFAULT_PAGE_SIZE = 10;

const setHttp = (link: string) => {
  if (link.search(/^http[s]?:\/\//) == -1) {
    link = 'http://' + link;
  }
  return link;
};

const parseUniversity = (record: UniversityResponse): University => ({
  id: record.id,
  universityId: record.unitId,
  schoolName: record.schoolName,
  schoolType: record.schoolType,
  schoolSize: record.schoolSizeGroup,
  street: record.street,
  city: record.city,
  state: record.state,
  zipcode: record.zipcode?.zipcode,
  phone: record.phone,
  url: record.website ? setHttp(record.website?.toLocaleLowerCase()) : '',
  image: record.image,
  gender: record.genderType,
  religiousAffiliation: record.religiousAffiliation,
  institutionControl: record.institutionControl,
  admissionSelectivity: Number(record.admissionSelectivity),
  enrollmentRate: record.enrollmentRate,
  satMathMedian: record.mathMedian,
  satWriteMedian: record.sat_write_median,
  satVerbalMedian: record.verbalMedian,
  actComprehensive: record.actComprehensiveScore,
  averageGraduationRate: record.average6YearGraduationRate,
  medianGraduateIncome: record.gradMedianIncome10YearPostEnrollment,
  schoolSettings: record?.zipcode?.locales?.map((item) => ({
    title: item?.locale.locale,
    group: item?.locale.locale_group,
  })),
  majors: record?.majors?.map((item) => ({ id: item.majorId })),
  ethnicities: record?.ethnicities?.map((item) => ({
    id: item.ethnicityId,
    enrollmentRate: item.enrollmentRate,
  })),
  averageHouseholdIncome: record?.avgNetPrices?.map((item) => ({
    familyIncomeRange: item.familyIncomeRange,
    averageTotalCostOfAttendance: item.averageTotalCostOfAttendance,
  })),
  averageNetCost: record.averageTotalCostOfAttendance,
  tuitionInState: record.tuitionFeeInstate,
  tuitionOutState: record.tuitionFeeOutstate,
});
export async function getUniversities(queryParams: { searchTerm: string; page: number }): Promise<University[]> {
  const DEFAULT_PAGE_SIZE = 20;
  const searchTerm = get(queryParams, 'searchTerm', '');
  const page = get(queryParams, 'page', '');
  try {
    const url = `${API_URL}/universities`;
    const params: { [key: string]: any } = {
      offset: Number(page || 0) * DEFAULT_PAGE_SIZE,
      limit: DEFAULT_PAGE_SIZE,
    };
    if (searchTerm) {
      params['search'] = `${searchTerm}`;
    }
    const response = await axios.get(url, { params });
    return responseParser(response).map(parseUniversity);
  } catch (error) {
    throw createError(error);
  }
}
getUniversities.QUERY_KEY = 'public:getUniversities';

const parseMajorClassification = ({ id, displayText }: { id: string; displayText: string }): MajorClassification => ({
  id,
  displayText,
});
export async function getMajorsClassifications(): Promise<MajorClassification[]> {
  const url = `${API_URL}/public-catalog/major-classification`;
  try {
    const response = await axios.get(url);
    return responseParser(response).map(parseMajorClassification);
  } catch (error) {
    throw createError(error);
  }
}
getMajorsClassifications.QUERY_KEY = 'public:getMajorsClassifications';

const parseEthnicity = (record: { id: string; displayText: string }): Ethnicity => ({
  id: record.id,
  displayText: record.displayText,
});
export async function getEthnicities(): Promise<Ethnicity[]> {
  const url = `${API_URL}/public-catalog/ethnicity`;
  try {
    const response = await axios.get(url);
    return responseParser(response).map(parseEthnicity);
  } catch (error) {
    throw createError(error);
  }
}
getEthnicities.QUERY_KEY = 'public:getEthnicities';

export async function searchUniversities(
  oFilterQuery?: CollegeFinderFilters
): Promise<{ universities: University[]; totalCount: number }> {
  const filterQuery =
    produce(oFilterQuery, (draftQuery) => {
      Object.assign(draftQuery, {});
    }) || {};

  const filters = filterQuery && stringifyCollegeQueryFilter(filterQuery);
  const sort: keyof University = 'schoolName';
  const limit = DEFAULT_PAGE_SIZE;

  const url = `${API_URL}/universities?sort=${sort}&limit=${limit}${filters ?? ''}`;
  try {
    const response = await axios.get(url);
    const result = {
      universities: responseParser(response).map(parseUniversity),
      totalCount: response.data.totalCount,
    };
    return result;
  } catch (err) {
    throw createError(err);
  }
}
searchUniversities.QUERY_KEY = 'public:searchUniversities';

export async function findUniversityById(id: string): Promise<University> {
  const url = `${API_URL}/universities?ids=${id}`;
  try {
    const response = await axios.get(url);
    return parseUniversity(responseParser(response)[0]);
  } catch (error) {
    throw createError(error);
  }
}
findUniversityById.QUERY_KEY = 'public:findUniversityById';
