import React, { useContext } from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { Row, Col, Divider, Breadcrumb } from 'antd';
import { useQuery } from 'react-query';
import NavigateNext from '@material-ui/icons/NavigateNext';
import produce from 'immer';
import { isEmpty } from 'lodash';

import { CollegeFinderContext } from './context';
import { StyledUrl, TitleRow, TitleCol, StyledLabel, StyledContainer } from './styles';

import { StyledImage } from '../../templates/basic-styles';
import theme from '../../theme';
import { findUniversityById } from '../../apis/public/college-finder';
import { formatPhoneNumber, currencyFormatter } from '../../utils/common';
import { NavigationWrapper } from '../../components/common/page-header';
import Loader from '../../components/common/loader';
import { Alert } from '../../components';
import { AverageHouseholdIncome } from '../../types/models/college-finder';

const StyledDivCard = styled.div`
  color: ${theme.colorsBlack};
  font-size: 18px;
  margin-bottom: 16px;
`;

type CollegeFinderDetailCardProps = {
  label: string;
  data: React.ReactNode;
};

const CollegeDetailCard: React.FC<CollegeFinderDetailCardProps> = ({ label, data }) => {
  if (isEmpty(data)) {
    return null;
  }

  return (
    <>
      <StyledLabel>{label}</StyledLabel>
      <StyledDivCard>{data}</StyledDivCard>
    </>
  );
};

const StyledDiv = styled.div`
  color: ${theme.colorsBlack};
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 5px;
`;

const StyledDataDiv = styled.div`
  font-size: 14px;
  color: ${theme.colorsDisabledGreyText};
  line-height: 24px;
`;

const StyledButton = styled.button`
  border: none;
  background: transparent;
  text-decoration: underline;
  cursor: pointer;
  color: ${theme.colorsSecondaryBlue};
`;

type CollegeFinderDetailsProps = {
  id?: string;
};

enum HouseholdIncomeRanges {
  '<$30,000' = '0-30,000',
  '$30,001-$48,000' = '30,001-48,000',
  '$48,001-$75,000' = '48,001-75,000',
  '$75,001-$110,000' = '75,001-110,000',
  '>$110,001' = '110,001+',
}

enum HouseholdIncomeRangesScore {
  '0-30,000' = 0,
  '30,001-48,000' = 1,
  '48,001-75,000' = 2,
  '75,001-110,000' = 3,
  '110,001+' = 4,
}

const HouseholdIncomeRangesLabels: { [key: string]: string } = {
  [HouseholdIncomeRanges['<$30,000']]: `For <$30,000 Average Household Income (AHI)`,
  [HouseholdIncomeRanges['$30,001-$48,000']]: `For $30,001-$48,000 AHI`,
  [HouseholdIncomeRanges['$48,001-$75,000']]: `For $48,001-$75,000 AHI`,
  [HouseholdIncomeRanges['$75,001-$110,000']]: `For $75,001-$110,000 AHI`,
  [HouseholdIncomeRanges['>$110,001']]: `For >$110,001 AHI`,
};

export default function CollegeFinderDetails({ id }: RouteComponentProps<CollegeFinderDetailsProps>) {
  const context = useContext(CollegeFinderContext);
  const {
    data: college,
    isLoading,
    error,
  } = useQuery(
    [findUniversityById.QUERY_KEY, { id }],
    ({ queryKey }: { queryKey: (string | { id: string | undefined })[] }) => findUniversityById(queryKey[1].id),
    { enabled: !!id }
  );

  const [extendMajors, setExtendMajors] = React.useState(false);

  const generateBreadcrumb = React.useCallback((name?: string) => {
    return [
      <Link key="1" to={'/'}>
        Home
      </Link>,
      <Link key="1" to={'/college-finder'}>
        Resources: College Finder
      </Link>,
      name && `${name}`,
    ];
  }, []);

  const renderComma = (item = '') => (item !== null && item !== ' ' ? item + ', ' : '');

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return (
      <StyledContainer>
        <Alert message={error.message} type="error" />
      </StyledContainer>
    );
  }

  const collegeMajorsVisibilityIndex = 5;

  const collegeMajors = college?.majors
    ?.map((item) => {
      const major = context.majors?.find((major) => major.id === item.id);
      return produce(item, (draft) => {
        draft.displayText = major?.displayText;
      });
    })
    .sort((a, b) => a?.displayText?.localeCompare(b.displayText ?? '') ?? 0);

  console.log('context.ethnicities', context.ethnicities);

  const collegeEthnicities = college?.ethnicities?.map((item) => {
    const ethnicity = context.ethnicities?.find((ethnicity) => ethnicity.id === item.id);
    return produce(item, (draft) => {
      draft.displayText = ethnicity?.displayText;
    });
  });

  const averageHouseholdIncomes = college?.averageHouseholdIncome?.sort(
    (a: AverageHouseholdIncome, b: AverageHouseholdIncome) => {
      return (
        HouseholdIncomeRangesScore[a.familyIncomeRange as keyof typeof HouseholdIncomeRangesScore] -
        HouseholdIncomeRangesScore[b.familyIncomeRange as keyof typeof HouseholdIncomeRangesScore]
      );
    }
  );

  return (
    college && (
      <StyledContainer>
        <NavigationWrapper>
          <Breadcrumb separator={<NavigateNext />}>
            {generateBreadcrumb(college.schoolName).map((item, index) => (
              <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </NavigationWrapper>
        <TitleRow>
          <TitleCol>{college.schoolName}</TitleCol>
        </TitleRow>
        {college.image && (
          <Row>
            <Col xs={24} sm={24} md={16} lg={16}>
              <StyledImage src={college.image} alt={college.schoolName} />
            </Col>
          </Row>
        )}
        <StyledDiv>
          <div>
            {renderComma(college.street)} {renderComma(college.city)} {college.state} {college.zipcode}
          </div>
          <div>{formatPhoneNumber(college?.phone || '')}</div>
          <StyledUrl href={college.url} target="_blank" rel="noopener noreferrer">
            {college.url}
          </StyledUrl>
        </StyledDiv>
        <Divider />
        <CollegeDetailCard label="School Type" data={college.schoolType} />
        <CollegeDetailCard label="School Type" data={college.institutionControl} />
        <CollegeDetailCard label="School Size" data={college.schoolSize} />
        <CollegeDetailCard label="Gender" data={college?.gender} />
        <CollegeDetailCard label="Religious Affiliation" data={college.religiousAffiliation} />
        {college?.schoolSettings?.length && (
          <CollegeDetailCard
            label="School Setting"
            data={college.schoolSettings.map((item, index) => (
              <div key={index}>{item.group}</div>
            ))}
          />
        )}
        <CollegeDetailCard
          label="Admissions Selectivity"
          data={<>{Math.round((college.admissionSelectivity || 0) * 100).toFixed(2)}%</>}
        />
        <CollegeDetailCard
          label="Test Score"
          data={
            <>
              <div>SAT Math - {college?.satMathMedian ?? ''}</div>
              <div>Evidence-based Reading and Writing - {college?.satVerbalMedian ?? ''}</div>
              <div>ACT Comprehensive - {college?.actComprehensive ?? ''}</div>
            </>
          }
        />
        <CollegeDetailCard
          label="Majors"
          data={
            collegeMajors?.some((major) => major?.displayText) &&
            collegeMajors?.map((item, index) => {
              const nodeItem = extendMajors ? (
                <div key={index}>{item.displayText}</div>
              ) : (
                index < collegeMajorsVisibilityIndex && <div key={index}>{item.displayText}</div>
              );
              if (index + 1 === collegeMajors.length) {
                return (
                  <React.Fragment key={index}>
                    {nodeItem}
                    <StyledButton onClick={() => (extendMajors ? setExtendMajors(false) : setExtendMajors(true))}>
                      {extendMajors ? 'See less' : 'See all'}
                    </StyledButton>
                  </React.Fragment>
                );
              }
              return nodeItem;
            })
          }
        />
        <CollegeDetailCard
          label="Race/Ethnicity"
          data={collegeEthnicities?.map((item, index) => (
            <div key={index}>
              {item.displayText} - {Math.round((item.enrollmentRate || 0) * 100).toFixed(2)}%
            </div>
          ))}
        />
        <CollegeDetailCard
          label="Average Net Cost"
          data={
            <>
              <StyledDataDiv>
                The information listed below does not represent actual costs, these are averages based on data provided
                by the Department of Education. Costs vary according to individual financial situation. The average net
                costs reflected on this page represent the total cost of attendance, including tuition and fees, books
                and supplies, and living expenses, minus the average grant/scholarship aid, by income category. It is
                calculated for all full-time, first-time, degree/certificate-seeking undergraduates who receive Federal
                Financial Aid.
              </StyledDataDiv>
              <div style={{ margin: '10px 0' }}>
                {averageHouseholdIncomes?.map((item, index) => (
                  <div key={index}>
                    {`${HouseholdIncomeRangesLabels[item.familyIncomeRange]}: ${currencyFormatter.format(
                      item.averageTotalCostOfAttendance
                    )}`}
                  </div>
                ))}
              </div>
              <div>{`In-State Tuition: ${currencyFormatter.format(college.tuitionInState)}`}</div>
              <div>{`Out-of-State Tuition: ${currencyFormatter.format(college.tuitionOutState)}`}</div>
            </>
          }
        />

        <CollegeDetailCard
          label="Average Graduation Rate"
          data={<>{`${(college.averageGraduationRate * 100).toFixed(2)}%`}</>}
        />
        <CollegeDetailCard
          label="Median Graduate Income (10 years after enrollment)"
          data={<>{`${currencyFormatter.format(college.medianGraduateIncome)}`}</>}
        />
      </StyledContainer>
    )
  );
}
