import styled from '@emotion/styled';
import React, { useMemo, useState, useContext, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import produce from 'immer';

import { useQuery } from '../../../hooks/use-query';
import { CollegeFinderContext } from '../context';
import { FormElementChangeEvent, Select } from '../../../components/forms';
import { OptionProps } from '../../../components/forms/select';
import { University as UniversityModel } from '../../../types/models/college-finder';
import { getUniversities } from '../../../apis/public/college-finder';

const StyledSelect = styled(Select)`
  box-shadow: none !important;
  border-radius: 4px !important;
  line-height: 1.5 !important;
  border: 1px solid #aaa;

  &&::placeholder {
    color: #aaa;
  }

  && .ant-select-selector {
    min-height: 42px !important;
    border: none !important;
  }

  && .ant-select-selection-placeholder {
    line-height: 42px !important;
  }
`;

const mapToOptions = ({ schoolName: label, id: value }: Partial<University>): OptionProps => ({
  label,
  value,
});

type University = Pick<UniversityModel, 'id' | 'universityId' | 'schoolName'>;
type UniversityMap = {
  [key: string]: University;
};

const CollegesAutocomplete = ({
  clearFilterCollegeName,
  clearColleges,
}: {
  clearFilterCollegeName: boolean;
  clearColleges: (value: boolean) => void;
}) => {
  const context = useContext(CollegeFinderContext);
  const [selectValue, setSelectValue] = useState<string[]>([]);
  const [filterSearchText, setfilterSearchText] = useState('');
  const [universities, setUniversities] = useState<University[]>([]);
  const [filterPage, setFilterPage] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    if (clearFilterCollegeName) {
      setSelectValue([]);
    }
  }, [clearFilterCollegeName]);

  const { isLoading: isLoadingUniversities } = useQuery(
    [getUniversities.QUERY_KEY, { searchTerm: filterSearchText, page: filterPage[filterSearchText] || 0 }],
    ({ queryKey }: any) => getUniversities(queryKey[1]),
    {
      onError: (error) => {
        context && context.setError && context.setError(error as Error);
      },
      onSuccess: (data) => {
        setUniversities((previous) => {
          const previousUMap = previous.reduce(
            (result: UniversityMap, university) =>
              produce(result, (draft) => {
                draft[university.id] = university;
              }),
            {}
          );

          const currentDataUMap = data.reduce(
            (result: UniversityMap, university) =>
              produce(result, (draft) => {
                draft[university.id] = university;
              }),
            {}
          );

          return Object.values(
            produce(previousUMap, (draft) => {
              Object.assign(draft, currentDataUMap);
            })
          ).sort((a: University, b: University) => {
            if (!a || !b) {
              return 0;
            }
            if (a && b && a.schoolName < b.schoolName) {
              return -1;
            }
            if (a.schoolName > b.schoolName) {
              return 1;
            }
            return 0;
          });
        });
      },
    }
  );

  const _nextFilterPage = useCallback(() => {
    setFilterPage((previous) =>
      produce(previous, (draft) => {
        if (filterSearchText in draft) {
          draft[filterSearchText] += 1;
        } else {
          draft[filterSearchText] = 1;
        }
      })
    );
  }, [filterSearchText]);

  const _setfilterSearchText = (searchTerm: string) => {
    setfilterSearchText(searchTerm);
  };

  const handleSelectChange = (e: FormElementChangeEvent) => {
    clearColleges(false);
    setSelectValue(e.value);
    context.setFilters({ ids: e.value.map((option: OptionProps) => option.value) });
    _setfilterSearchText('');
  };

  const options = useMemo(() => {
    return universities.length ? universities.map(mapToOptions) : [];
  }, [universities]);

  const onScroll = useCallback(
    (e) => {
      const target = e.target;
      if (!isLoadingUniversities && target.scrollTop + target.offsetHeight === target.scrollHeight) {
        _nextFilterPage();
      }
    },
    [isLoadingUniversities, _nextFilterPage]
  );

  const onSearch = useCallback(
    debounce((value: string) => {
      if (value.length > 3 || value.length === 0) {
        _setfilterSearchText(value);
      }
    }, 300),
    []
  );

  return (
    <StyledSelect
      placeholder="Select one or more"
      mode="multiple"
      showSearch
      value={selectValue}
      labelInValue
      optionFilterProp="label"
      filterOption={(input: any, option: any) =>
        option &&
        option.props !== null &&
        option.props.children !== null &&
        typeof option.props.children === 'string' &&
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      options={options}
      loading={isLoadingUniversities}
      autoClearSearchValue={false}
      onChange={handleSelectChange}
      onSearch={onSearch}
      onPopupScroll={onScroll}
    />
  );
};

export default CollegesAutocomplete;
