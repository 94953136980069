import React from 'react';

import { IFilter } from '../../types/models/college-finder';
import { OptionProps } from '../../components/forms/select';

export interface ISectionItem {
  label: string;
  name: keyof IFilter;
  options: OptionProps;
  tooltip?: string | React.ReactNode;
}

interface Section {
  sectionLabel: string;
  items: ISectionItem[];
}

// TODO refactor this
const SATmathEvidence: OptionProps = [
  { label: 'Clear Filter', value: null },
  { label: '<400', value: '0,400' },
  { label: '400-500', value: '400,500' },
  { label: '500-600', value: '500,600' },
  { label: '600-700', value: '600,700' },
  { label: '>700', value: '700,900' },
];

const ACTcomprehensive: OptionProps = [];
ACTcomprehensive.push({ value: null, label: 'Clear Filter' });
for (let i = 1; i <= 36; i++) {
  ACTcomprehensive.push({ value: i.toString(), label: i.toString() });
}

const AdmissionsSelectivity = [
  { label: 'Clear Filter', value: null },
  { label: '>75% admitted', value: '0.75,1' },
  { label: '50%-75% admitted', value: '0.5,0.75' },
  { label: '25%-49% admitted', value: '0.25,0.49' },
  { label: '10%-24% admitted', value: '0.1,0.24' },
  { label: '<10% admitted', value: '0,0.1' },
];

const TypeOfSchool = [
  { value: 'Public', label: 'Public' },
  { value: 'Private not-for-profit', label: 'Private not-for-profit' },
];

const SchoolSize = [
  { label: 'Small', value: 'Small' },
  { label: 'Medium', value: 'Medium' },
  { label: 'Large', value: 'Large' },
];

const Gender = [
  { label: 'Coed', value: 'Coed' },
  { label: 'Female only', value: 'Female Only' },
  { label: 'Male only', value: 'Male Only' },
];

export const States = [
  { label: 'Alaska', value: 'AK' },
  { label: 'Alabama', value: 'AL' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'District of Columbia', value: 'DC' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Maine', value: 'ME' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Montana', value: 'MT' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New York', value: 'NY' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Washington', value: 'WA' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wyoming', value: 'WY' },
];

const Setting = [
  { label: 'Rural', value: 'Rural' },
  { label: 'Suburban', value: 'Suburban' },
  { label: 'City', value: 'City' },
];

const Major: [] = [];

const AverageHouseholdIncome = [
  { label: 'Clear Filter', value: null },
  { label: '<= $30,000', value: '30,000' },
  { label: '$30,001-$48,000', value: '30,001-48,000' },
  { label: '$48,001-$75,000', value: '48,001-75,000' },
  { label: '$75,001-$110,000', value: '75,001-110,000' },
  { label: '> $110,001', value: '110,001+' },
];

const AverageNetCost = [
  { label: 'Clear Filter', value: null },
  { label: '<$15,000', value: '<15000' },
  { label: '$15,001-$30,000', value: '15001-30000' },
  { label: '$30,001-$45,000', value: '30001-45000' },
  { label: '$45,001-$60,000', value: '45001-60000' },
  { label: '$60,001-$75,000', value: '60001-75000' },
  { label: '>$75,000', value: '>75000' },
];

const PercentFinancialAid = [
  { label: 'Clear Filter', value: null },
  { label: '<10%', value: '<0.1' },
  { label: '10%-20%', value: '0.1-0.2' },
  { label: '21%-30%', value: '0.21-0.3' },
  { label: '31%-40%', value: '0.31-0.4' },
  { label: '>40%', value: '>0.4' },
];

const RaceEthnicity = [
  { label: 'American Indian or Alaska Native', value: 'American Indian or Alaska Native' },
  { label: 'Black or African American', value: 'Black or African American' },
  { label: 'Hispanic or Latino', value: 'Hispanic or Latino' },
  { label: 'Native Hawaiian or Other Pacific Islander', value: 'Native Hawaiian or Other Pacific Islander' },
  { label: 'Two or more races', value: 'Two or more races' },
  { label: 'White', value: 'White' },
];

const RaceEthnicityEnrollmentRate = [
  { label: 'Clear Filter', value: null },
  { label: '<5%', value: '<0.05' },
  { label: '5%-10%', value: '0.05-0.1' },
  { label: '11%-15%', value: '0.11-0.15' },
  { label: '16%-20%', value: '0.16-0.2' },
  { label: '21%-25%', value: '0.21-0.25' },
  { label: '>25%', value: '>0.25' },
];

const AverageGraduationRate = [
  { label: 'Clear Filter', value: null },
  { label: '<25%', value: '<0.25' },
  { label: '25%-50%', value: '0.25-0.5' },
  { label: '51%-75%', value: '0.51-0.75' },
  { label: '76%-90%', value: '0.76-0.9' },
  { label: '91%-100%', value: '0.91-1' },
];

const MedianGraduateIncome = [
  { label: 'Clear Filter', value: null },
  { label: '< $50,000', value: '<50000' },
  { label: '$50,000-$75,000', value: '50000-75000' },
  { label: '$75,000-$100,000', value: '75000-100000' },
  { label: '> $100,000', value: '>100000' },
];

export const formSchema: Section[] = [
  {
    sectionLabel: 'Admissions',
    items: [
      {
        label: 'Test Score (SAT Math)',
        name: 'mathMedian',
        options: SATmathEvidence,
      },
      {
        label: 'Test Score (Evidence Based Reading & Writing)',
        name: 'verbalMedian',
        options: SATmathEvidence,
      },
      {
        label: 'Test Score (ACT Comprehensive)',
        name: 'actComprehensiveScore',
        options: ACTcomprehensive,
      },
      {
        label: 'Admissions Selectivity (% of undergraduate applicants admitted)',
        name: 'enrollmentRate',
        options: AdmissionsSelectivity,
      },
    ],
  },
  {
    sectionLabel: 'Type of School',
    items: [
      {
        label: 'Type of school',
        name: 'institutionControl',
        options: TypeOfSchool,
      },
      {
        label: 'School Size',
        name: 'schoolSizeGroup',
        options: SchoolSize,
        tooltip: (
          <>
            <div>{'Small: <5,000'}</div>
            <div>{'Medium: 5,000 - 19,999'}</div>
            <div>{'Large: 20,000+'}</div>
          </>
        ),
      },
      {
        label: 'Gender',
        name: 'genderTypes',
        options: Gender,
      },
    ],
  },
  {
    sectionLabel: 'Location',
    items: [
      {
        label: 'State',
        name: 'states',
        options: States,
      },
      {
        label: 'Setting',
        name: 'localeGroups',
        options: Setting,
      },
    ],
  },
  {
    sectionLabel: 'Academics',
    items: [
      {
        label: 'Major',
        name: 'majors',
        options: Major,
      },
    ],
  },
  {
    sectionLabel: 'Cost & Financial Aid',
    items: [
      {
        label: 'Average Household Income',
        name: 'familyIncomeRange',
        options: AverageHouseholdIncome,
      },
      {
        label: 'Average Net Cost for Income Level Selected',
        name: 'averageTotalCostOfAttendance',
        options: AverageNetCost,
      },
      {
        label: 'Percentage of students receiving financial aid',
        name: 'percentStudentsReceivingFinancialAid',
        options: PercentFinancialAid,
      },
    ],
  },
  {
    sectionLabel: 'Demographics',
    items: [
      {
        label: 'Race/Ethnicity',
        name: 'ethnicities',
        options: RaceEthnicity,
      },
      {
        label: 'Percent of Race/Ethnicity',
        name: 'ethnicitiesEnrollmentRate',
        options: RaceEthnicityEnrollmentRate,
      },
    ],
  },
  {
    sectionLabel: 'Outcomes',
    items: [
      {
        label: 'Average Graduation Rate (6-year)',
        name: 'averageGraduationRate',
        options: AverageGraduationRate,
      },
      {
        label: 'Median Graduate Income (10 years after enrollment)',
        name: 'medianGraduateIncome',
        options: MedianGraduateIncome,
      },
    ],
  },
];
