import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import React, { useState, useContext } from 'react';

import MoreFilters from './more-filters';
import CollegesAutocomplete from './universities-filter';

import { CollegeFinderContext } from '../context';
import { States } from '../schema';
import { FormElementChangeEvent, Select, Input, InputWithWrapper } from '../../../components/forms';
import { CollegeFinderFilters } from '../../../types/models/college-finder';
import { DefaultSearchIcon } from '../../../components/admin/common/common-styled-component';
import theme from '../../../theme';

const StyledLabel = styled.div`
  color: ${theme.colorsBlack};
  font-weight: bold;
  font-size: 16px;
  margin: 5px 0;
`;

const StyledMoreFilters = styled.div`
  margin: 5px 0;
  @media (min-width: ${theme.screenSm}) {
    margin-top: 45px;
  }
`;

export const FakeBox = styled.div`
  background: white;
  opacity: 1;
  border-radius: 4px;
  border: solid 1px #d1d3d6;
  font-size: 14px;
`;

export const FixedInput = styled(Input)`
  border: none;
  border-color: transparent;
  width: calc(100% - 26px);
`;

const Container = styled(Row)`
  min-height: 250px;
  @media (min-width: ${theme.screenSm}) {
    min-height: 110px;
  }
  @media (min-width: ${theme.screenLg}) {
    min-height: 50px;
  }
`;

const Filters: React.FC<{}> = () => {
  const context = useContext(CollegeFinderContext);

  const [searchInput, setSearchInput] = useState(context.filters?.searchText);
  const [clearFilterCollegeName, setClearFilterCollegeName] = useState<boolean>(false);

  const onSearchInputChange = (e: FormElementChangeEvent) => {
    setSearchInput(e.value);
    if (searchInput && !e.value) {
      context.setFilters({ searchText: undefined });
    }
  };

  const onSearchEnter = (e: any) => {
    context.setFilters({ searchText: e.currentTarget.value.trim() });
  };

  const getQueryFieldChangeHandler = (field: keyof CollegeFinderFilters) => (e: FormElementChangeEvent) => {
    context.setFilters({ [field]: e.value });
  };

  const clearColleges = () => {
    setClearFilterCollegeName(false);
  };

  const clearFieldsOnClearFilters = () => {
    setSearchInput('');
    setClearFilterCollegeName(true);
    context.setFilters({ searchText: undefined });
    context.setFilters({ ids: undefined });
  };

  return (
    <>
      <Container justify="space-between" gutter={10}>
        <Col xs={24} sm={11} md={11} lg={7}>
          <StyledLabel>Search</StyledLabel>
          <InputWithWrapper
            prefix={<DefaultSearchIcon />}
            placeholder="Search by name"
            onPressEnter={onSearchEnter}
            value={searchInput}
            onChange={onSearchInputChange}
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={9}>
          <StyledLabel>Filter by College Name</StyledLabel>
          <CollegesAutocomplete clearFilterCollegeName={clearFilterCollegeName} clearColleges={clearColleges} />
        </Col>
        <Col xs={24} sm={11} md={11} lg={6}>
          <StyledLabel>Filter by State</StyledLabel>
          <Select
            mode="multiple"
            showSearch
            placeholder="Select one or more"
            value={context.filters?.states}
            options={States}
            optionFilterProp="children"
            onChange={getQueryFieldChangeHandler('states')}
            filterOption={(input: any, option: any) =>
              option &&
              option.props !== null &&
              option.props.children !== null &&
              typeof option.props.children === 'string' &&
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={2}>
          <StyledMoreFilters>
            <MoreFilters clearFields={clearFieldsOnClearFilters} />
          </StyledMoreFilters>
        </Col>
      </Container>
    </>
  );
};

export default Filters;
